// eslint-disable-next-line no-restricted-syntax
import { Emitter } from '@pixi/particle-emitter';
// eslint-disable-next-line no-restricted-syntax
import { Application, Container } from 'pixi.js';

export class ParticleView extends Container {
  public emitter: Emitter;
  public get Emitter() {
    return this.emitter;
  }

  private now: number = Date.now();

  constructor(config: any, app: Application, cointainer: Container, autoPlay = false) {
    super();
    this.emitter = new Emitter(this, config);
    if (!app) return;
    if (!config?.autoPlay) {
      this.now = Date.now();
      app.ticker.add(() => {
        const newNow = Date.now();
        this.emitter.update((newNow - this.now) * 0.001);
        this.now = newNow;
      });
    }

    this.emitter.updateOwnerPos(app.screen.width / 2, app.screen.height / 2);
    if (!config?.emit && this?.emitter?.emit) this.emitter.emit = autoPlay;

    cointainer.addChild(this);

    app.renderer.on('resize', () => {
      this.emitter.updateOwnerPos(app.screen.width / 2, app.screen.height / 2);
    });
  }

  public play(checkisPlay = false) {
    if (checkisPlay && this.emitter.emit) return;
    this.emitter.emit = true;
  }

  public stop() {
    this.emitter.emit = false;
  }
}
