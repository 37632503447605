export type PuzzleGame = {
  category: string;
  title: string;
  playlist: string;
  image: string;
  companyLogo: string;
  companyName: string;
  icon: string;
  url: string;
  categoryTitle: string;
  categoryDescription: string;
  activity: string;
  visibility?: 'public' | 'internal';
};

export type TutorialPage = {
  title: string;
  image: string;
};

export type Tutorial = {
  activity: string;
  pages: TutorialPage[];
};

export type ActivityInfo = {
  name: string;
  background: string;
  title: string;
  subTitle: string;
  textColor?: string;
  headerThemeColor: string;
  videoId: string;
  description: {
    choose: string;
    host: string;
    start: string;
  };
  labsThemeColor: string;
  firstLabThemeColor: string;
};

const multiplicationTitle: string = 'Multiplication Puzzle';
const multiplicationDescription: string =
  'Explore multiplication by playing through an adaptive puzzle game: create arrays on the game board, advance to new levels, gather coins and fill the chest!';

const fractionsTitles: string[] = [
  'Discovering fractions!',
  'Fractions and the Number line',
  'Fractions over 1',
  'Comparing fractions',
  'Equivalent fractions',
  'Hardcore 💀💀',
  'Playtest',
];
const fractionsDescriptions: string[] = [
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd grade math standards: students will <strong>identify and combine unit fractions</strong> and work with <strong>non-unit fractions up to 1</strong>.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd grade math standards: students will <strong>represent and identify fractions on the number line</strong> and see their relationship with <strong>whole numbers</strong>.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd and 4th grade math standards: students will <strong>explore fractions over 1</strong> on the number line and add and subtract fractions and <strong>mixed numbers</strong>.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd and 4th grade math standards: students will <strong>explore, assess and reason about fraction magnitude</strong> with unit and non-unit fractions.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd and 4th grade math standards: students will <strong>explore fraction equivalence up to 1</strong>.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is aligned to 3rd and 4th grade math standards: students will work with fractions in <strong>tricky situations</strong> that require them to assess, add and subtract <strong>non-unit fractions</strong> and work with <strong>mixed numbers</strong>.',
  'Use fractions to help the duck reach the rocket.<br /><br /> This session is used for internal purposes only',
];

export const puzzles: PuzzleGame[] = [
  {
    category: 'Test',
    playlist: 'alejandrotestlist',
    title: 'Alejandro Test List',
    image: '/multiplayer/player/overlay/card-info/card-info-visual.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/facts2-5',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Test',
    playlist: 'randomfacts5to12_meira',
    title: 'Meira Test List',
    image: '/multiplayer/features/multiplication-hub/facts_for_7_8.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/randomfacts5to12_meira',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Presentation',
    title: 'Facts up to 5',
    playlist: 'facts2-5',
    image: '/multiplayer/features/multiplication-hub/facts_up_to_5.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/facts2-5',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Mixed Facts',
    title: 'Facts up to 10 (easy)',
    playlist: 'smallfactsupto10',
    image: '/multiplayer/features/multiplication-hub/facts_up_to_10easy.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/smallfactsupto10',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Mixed Facts',
    title: 'Facts up to 10 (hard)',
    playlist: 'randomfacts2to10',
    image: '/multiplayer/features/multiplication-hub/facts_up_to_10_hard.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/randomfacts2to10',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Mixed Facts',
    title: 'Facts for 5, 6, 7, 8 and 9',
    playlist: 'facts1',
    image: '/multiplayer/features/multiplication-hub/facts_for_5_6_7_8_9.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/facts1',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Mixed Facts',
    title: 'Facts for 6, 7, 8 and 9',
    playlist: 'hardfacts6to9',
    image: '/multiplayer/features/multiplication-hub/facts_for_6_7_8_9.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/hardfacts6to9',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Mixed Facts',
    title: 'Facts up to 12',
    playlist: 'randomfacts5to12',
    image: '/multiplayer/features/multiplication-hub/facts_up_to_12.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/randomfacts5to12',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 2',
    playlist: 'table2',
    image: '/multiplayer/features/multiplication-hub/multiply_by_2.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table2',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },

  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 3',
    playlist: 'table3',
    image: '/multiplayer/features/multiplication-hub/multiply_by_3.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table3',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },

  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 4',
    playlist: 'table4',
    image: '/multiplayer/features/multiplication-hub/multiply_by_4.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table4',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 5',
    playlist: 'table5',
    image: '/multiplayer/features/multiplication-hub/multiply_by_5.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table5',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 6',
    playlist: 'table6',
    image: '/multiplayer/features/multiplication-hub/multiply_by_6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table6',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 7',
    playlist: 'table7',
    image: '/multiplayer/features/multiplication-hub/multiply_by_7.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table7',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 8',
    playlist: 'table8',
    image: '/multiplayer/features/multiplication-hub/multiply_by_8.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table8',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 9',
    playlist: 'table9',
    image: '/multiplayer/features/multiplication-hub/multiply_by_9.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table9',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Multiples by 10',
    playlist: 'table10',
    image: '/multiplayer/features/multiplication-hub/multiply_by_10.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/table10',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Facts for 2 and 4',
    playlist: 'arrays2and4',
    image: '/multiplayer/features/multiplication-hub/facts_for_2_4.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays2and4',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Facts for 3 and 6',
    playlist: 'arrays3and6',
    image: '/multiplayer/features/multiplication-hub/facts_for_3_6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays3and6',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Facts for 4 and 8',
    playlist: 'arrays4and8',
    image: '/multiplayer/features/multiplication-hub/facts_for_4_8.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays4and8',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Facts for 5 and 10',
    playlist: 'arrays5and10',
    image: '/multiplayer/features/multiplication-hub/facts_for_5_10.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays5and10',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },

  {
    category: 'Multiplication up to 12',
    title: 'Facts for 6 and 7',
    playlist: 'arrays6and7',
    image: '/multiplayer/features/multiplication-hub/facts_for_6_7.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays6and7',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },
  {
    category: 'Multiplication up to 12',
    title: 'Facts for 7 and 8',
    playlist: 'arrays7and8',
    image: '/multiplayer/features/multiplication-hub/facts_for_7_8.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/multiplication/arrays7and8',
    categoryTitle: multiplicationTitle,
    categoryDescription: multiplicationDescription,
    activity: 'multiplication',
  },

  {
    category: 'Presentation!',
    title: 'Discovering Fractions!',
    playlist: 'discovering-fractions-session',
    image: '/multiplayer/features/fractions-hub/fractions1.png',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/discovering-fractions-session',
    categoryTitle: fractionsTitles[0],
    categoryDescription: fractionsDescriptions[0],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: 'Numberline and Fractions',
    playlist: 'numberline-session',
    image: '/multiplayer/features/fractions-hub/fractions2.png',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/numberline-session',
    categoryTitle: fractionsTitles[1],
    categoryDescription: fractionsDescriptions[1],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: 'Fractions over one',
    playlist: 'over-one-session',
    image: '/multiplayer/features/fractions-hub/fractions3.png',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/over-one-session',
    categoryTitle: fractionsTitles[2],
    categoryDescription: fractionsDescriptions[2],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: 'Comparing fractions',
    playlist: 'comparing-fractions-session',
    image: '/multiplayer/features/fractions-hub/fractions4.png',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/comparing-fractions-session',
    categoryTitle: fractionsTitles[3],
    categoryDescription: fractionsDescriptions[3],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: 'Equivalent fractions',
    playlist: 'equivalent-fractions-session',
    image: '/multiplayer/features/fractions-hub/fractions5.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/equivalent-fractions-session',
    categoryTitle: fractionsTitles[4],
    categoryDescription: fractionsDescriptions[4],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: '💀💀 Hardcore fractions 💀💀',
    playlist: 'hardcore-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/hardcore-session',
    categoryTitle: fractionsTitles[5],
    categoryDescription: fractionsDescriptions[5],
    activity: 'fractions',
  },
  {
    category: 'no-category',
    title: 'Whole numbers intro',
    playlist: 'whole-numbers-intro',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/while-numbers-intro-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1',
    playlist: 'playtest-1-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1 - Part 1',
    playlist: 'playtest-1-part-1-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-part-1-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1 - Part 2',
    playlist: 'playtest-1-part-2-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-part-2-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1 - Part 3',
    playlist: 'playtest-1-part-3-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-part-3-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1 - Part 4',
    playlist: 'playtest-1-part-4-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-part-4-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
  {
    category: 'no-category',
    title: 'Playtest 1 - Part 5',
    playlist: 'playtest-1-part-5-session',
    image: '/multiplayer/features/fractions-hub/fractions6.jpg',
    companyLogo: '/multiplayer/features/multiplication-hub/db_ellipse.png',
    companyName: 'DragonBox',
    icon: '/multiplayer/features/multiplication-hub/check.svg',
    url: '/start/fractions/playtest-1-part-5-session',
    categoryTitle: fractionsTitles[6],
    categoryDescription: fractionsDescriptions[6],
    activity: 'fractions',
    visibility: 'internal',
  },
];

export const tutorials: Tutorial[] = [
  {
    activity: 'multiplication',
    pages: [
      {
        title: 'Work together to gather treasures and evolve the chest.',
        image: '/multiplayer/player/overlay/multiplication-tutorial/tuto1.gif',
      },
      {
        title: 'To earn coins, complete puzzles by reaching the goals.',
        image: '/multiplayer/player/overlay/multiplication-tutorial/tuto2.gif',
      },
      {
        title: 'Select a character.',
        image: '/multiplayer/player/overlay/multiplication-tutorial/tuto3.gif',
      },
      {
        title: 'To create characters, hold and drag. Release when you are done.',
        image: '/multiplayer/player/overlay/multiplication-tutorial/tuto4.gif',
      },
    ],
  },
  {
    activity: 'fractions',
    pages: [
      {
        title: 'Work together to gather treasures and evolve the chest.',
        image: '/multiplayer/player/overlay/duck-duck-tutorial/tuto1.gif',
      },
      {
        title: 'To earn coins, help <span class="nowrap">Duck-Duck</span> to reach its rocket.',
        image: '/multiplayer/player/overlay/duck-duck-tutorial/tuto2.gif',
      },
      {
        title: 'To move <span class="nowrap">Duck-Duck</span>, drag cards...',
        image: '/multiplayer/player/overlay/duck-duck-tutorial/tuto3.gif',
      },
      {
        title: '...and press Play!',
        image: '/multiplayer/player/overlay/duck-duck-tutorial/tuto4.gif',
      },
    ],
  },
];

export const activities: ActivityInfo[] = [
  {
    name: 'multiplication',
    background: '/multiplayer/features/multiplication-hub/bg_multiplication_hub.webp',
    title: 'Multiplication Crew',
    subTitle: 'Play with multiplication!',
    textColor: '#633274',
    headerThemeColor: '#FFE769',
    description: {
      choose: 'from the options below.',
      host: 'Host the game on the classroom screen. Students can join using their own devices with a game pin.',
      start: 'The game adapts to each student’s level.',
    },
    videoId: '799778078?h=c0549b926f',
    labsThemeColor: '#FFA800',
    firstLabThemeColor: 'linear-gradient(287.24deg, rgba(179, 65, 182, 0.19) 45.09%, #FF6FE8 113.54%)',
  },
  {
    name: 'fractions',
    background: '/multiplayer/features/fractions-hub/bg_fractions_hub.jpg',
    title: '<span className="nowrap">Duck-Duck</span> Fractions',
    subTitle: 'Play with fractions!',
    headerThemeColor: '#3B398C',
    description: {
      choose: 'from the options below.',
      host: 'Host the game on the classroom screen. Students can join using their own devices with a game pin.',
      start: 'The game adapts to each student’s level.',
    },
    videoId: '830193224?h=4d09d029fe',
    labsThemeColor: '#6764FF',
    firstLabThemeColor: 'linear-gradient(292.26deg, rgba(37, 36, 97, 0.19) 45.09%, rgb(76 81 224) 113.54%)',
  },
];

export function getPuzzleGameData(activity: string | undefined, playlist: string | undefined): PuzzleGame {
  // Get a game from the list of games with the same activity and playlist
  var puzzleGame: PuzzleGame = puzzles.find(
    (item) => item.playlist === playlist && item.activity === activity
  ) as PuzzleGame;
  if (!puzzleGame) throw new Error('No puzzle game found for activity ' + activity);
  return puzzleGame;
}

export function getPuzzleGamesData(activity: string | undefined): PuzzleGame[] {
  return puzzles.filter((item) => item.activity === activity);
}

export function getTutorialData(activity: string | undefined): Tutorial {
  var tutorial: Tutorial = tutorials.find((item) => item.activity === activity) as Tutorial;
  if (!tutorial) throw new Error('No tutorial found for activity ' + activity);
  return tutorial;
}

export function getActivityData(activity: string | undefined): ActivityInfo {
  var activityData: ActivityInfo = activities.find((item) => item.name === activity) as ActivityInfo;
  if (!activityData) throw new Error('No activity found with name ' + activity);
  return activityData;
}
